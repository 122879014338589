<template>
<b-row class="log-row align-items-center small">
    <b-col cols="2" class="groups-team" :id="'log_row' + log.id">
        <b-img rounded="circle" class="log-user-image" :src="avatar" />{{ user.username }}
        <UserPopover :target="'log_row' + log.id" :user="user"></UserPopover>
    </b-col>
    <b-col cols="2">
        {{date}}
    </b-col>
    <b-col cols="2">{{log.key}}</b-col>
    <b-col>{{log.value}}</b-col>
    <b-col>
        {{tournament.name}}
    </b-col>
</b-row>
</template>

<script>
export default {
    name: "LogRow",
    props: ["log", "user", "tournament"],
    components: {},
    data() {
        return {};
    },
    computed: {
        avatar(){
            return this.$images.userAvatar(this.user.id);
        },
        date(){
            return this.$date.custom(this.log.date, "DD MMM HH:mm");
        }
    },
    methods: {
        svg(path) {
            return this.$images.svg(path);
        }
    }
};
</script>
